import React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'
import SectionTitle from '../components/sectionTitle'
import Logo from '../components/logo'
import NavItem from '../components/navItem'

const Failure = () => (
  <>
    <Seo title={`Payment failed`} />
    <SectionTitle
      title={`Payment failed`}
      subtitle={`Please try another payment option or contact support`}
    />
    <section className="max-w-6xl mx-auto text-center my-12 px-12 sm:px-6">
      <div className={`mb-6`}>
        <div className="flex flex-wrap justify-center -mb-3">
          <NavItem to={`/`}>Home</NavItem>
          <NavItem to={`/info/support/`}>Support</NavItem>
        </div>
      </div>
      <p className="text-gray-500 text-xs">
        Drop an e-mail to{' '}
        <a href="mailto:viktor@justboil.me" className="normal">
          viktor@justboil.me
        </a>
        . We'll manually process your order.
      </p>
    </section>
    <section className="flex justify-center mt-12 px-6">
      <Link to="/">
        <Logo />
      </Link>
    </section>
  </>
)

export default Failure
