import React from 'react'
import { Link } from 'gatsby'

const NavItem = ({ to, href, small, current, className = '', children }) =>
  React.createElement(
    to ? Link : 'a',
    {
      to: to,
      href: href,
      target: href ? '_blank' : null,
      rel: href ? 'noreferrer' : null,
      className: `hover:text-blue-600 inline-flex items-center py-1 px-3 md:px-0 mb-3 md:mr-3 md:last:mr-0 ${
        small ? 'text-sm' : ''
      } ${current ? 'font-bold' : ''} ${className}`,
    },
    children
  )

export default NavItem
